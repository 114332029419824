:root {
  --panda-font:  'Mont', sans-serif;

  //Colors
  --main-color: 255, 255, 255;
  --mirror-color: 0, 0, 0;
  --revert-color: 0, 0, 0;

  //Image State
  --image-invert: invert(1);
  --image-mirror-invert: invert(0);
}

html.dark {
  //Colors
  --main-color: 0, 0, 0;
  --mirror-color: 255, 255, 255;
  --revert-color: 255, 255, 255;

  //Image State
  --image-invert: invert(0);
  --image-mirror-invert: invert(1);
}