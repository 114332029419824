@media screen and (min-width: 1367px) {
  html {
    font-size: 10px;
  }
}

@media screen and (min-width: 980px) and (max-width: 1366px) {
  html {
    font-size: 8px;
  }
}

@media screen and (max-width: 979px) {
  html {
    font-size: 1.6vmin;
  }
}

@media screen and (max-width: 450px) {
  html {
    font-size: 2.67vmin;
  }
}

body {
  margin: 0;
}

*, *:after, *:before {
  box-sizing: border-box;
}

body, input, button, textarea {
  font-family: 'Mont', sans-serif;
}

button {
  cursor: pointer;

  &:active {
    transform: scale(0.96);
  }
}

.container {
  width: 100%;
  max-width: 124rem;
  padding-left: 2rem;
  padding-right: 2rem;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}

.call-to-action-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;

  a {
    height: auto;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    border: none;
    outline: none;
    text-transform: capitalize;
    font-size: 2.8rem;
    text-decoration: none;
    font-weight: 600;
    position: relative;
    color: #DAF43F;
    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.1rem;
      display: block;
      background-image: linear-gradient(270deg, #5AE343 0%, #DAF43F 64%, #D0F300 100%);
    }
  }
}

::-moz-selection { /* Code for Firefox */
  color: rgba(var(--main-color), 1);
  background: rgba(var(--mirror-color), 1);
  transition: color 240ms, background 240ms;
}

::selection {
  color: rgba(var(--main-color), 1);
  background: rgba(var(--mirror-color), 1);
  transition: color 240ms, background 240ms;
}

@media screen and (max-width: 979px) {
  .call-to-action-wrapper {
    margin-top: 0;

    a {
      font-size: 1.6rem;
    }
  }
}

#background {
  object-fit: cover;
  width: 100%;
  height: 100%;
  background-color: rgba(var(--revert-color), 0.8);
  position: absolute;
  filter: brightness(0.6);
  top: 0;
  right: 0;
}