.event-in-mobile-container {
  background-color: white;
  width: 100vw;
  height: 100%;
  z-index: 11;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  position: absolute;
  padding: 35px 0;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    filter: blur(5px);
  }

  .event-title-container {
    width: 80%;
    min-height: 90px;
    background: rgba(0, 0, 0, 0.32);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    box-shadow: 0 0 7px 1px #000000b8;

    .event-title-left-container {
      display: flex;
      width: calc(100% - 3rem);

      .back {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 15px;

        .icon-back {
          background-color: #FFFFFF;
          width: 25px;
          height: 25px;
        }
      }
      .title-container {
        display: flex;
        flex-direction: column;
        align-items: self-start;
        width: calc(100% - 15px);
        padding-right: 15px;

        .title {
          color: white;
          font-weight: 800;
          font-size: 18px;
          line-height: 24px;
          text-transform: capitalize;
          display: block;
          outline: none;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        span {
          display: block;
          font-size: 10px;
          color: #b9b9b9;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          outline: none;
          width: 100%;
        }
      }
    }

    .icon-link {
      background-color: #FFFFFF;
    }

    .tooltip {
      .tooltiptext {
        padding: 5px;
      }
    }
  }

  .open-on-mobile {
    width: 80%;
    border-radius: 22px;
    min-height: 333px;
    background: rgba(0, 0, 0, 0.32);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 7px 1px #000000b8;

    .logo {
      object-fit: contain;
      width: 150px;
      height: 80px;
    }

    p {
      max-width: 230px;
      font-size: 20px;
      color: white;
      text-align: center;
      margin: 15px;
    }

    .open-in-app {
      display: inline-block;
      min-width: 230px;
      background: black;
      color: white;
      text-decoration: none;
      text-align: center;
      padding: 15px;
      border-radius: 100px;
      font-size: 18px;
    }
  }
}
