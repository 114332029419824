.rotate-container {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;

  .rotate-img {
    background-image: url("../../assets/icons/animated/rotate.gif");
    background-repeat: no-repeat;
    background-size: cover;
    width: 20vw;
    height: 20vw;
    display: flex;
    filter: invert(1);
  }
}