.kick_off_modal{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 461px;
    height: 304px;
    z-index: 10;
    position: absolute;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 20px;
    gap: 30px;

    .header_section{
        width: 100%;
        display: flex;
        justify-content: space-between;

        .mofal_name{
            margin-top: 10px;
        }

        .close_popup{
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
    }

    .modal_description{
        text-align: center;
        font-weight: 700;
        font-size: 16px;
        max-width: 316px;
    }

    .modal_body{
        width: 100%;
        margin: 0 10px;

        .select_lable{
            font-weight: 700;
            font-size: 16px;
        }

        .select_section{
            position: relative;
            .interval_select{
                width: 100%;
                height: 40px;
                font-weight: 600;
                font-size: 13px;
                line-height: 40px;
                border: 1px solid #E9E9E9;
                border-radius: 8px;
                margin-top: 10px;
                padding: 0 10px;
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .select_placeholder{
                    color: #000000;
                }

                svg{
                    rotate: -90deg;
                    path{
                        fill: black;
                    }
                }

                &.error {
                    border: 1px solid #EB4242;
                }
            }

            .select_time{
                display: none;
                width: 100%;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 8px;
                list-style-type: none;
                position: absolute;
                background-color: white;
                padding: 0 10px;
    
                li{
                    height: 40px;
                    line-height: 40px;
                    font-weight: 600;
                    font-size: 13px;
                    cursor: pointer;
                    &:hover{
                        background: #F7F7F7;
                    }
                }
            }

            &.active{
                .select_time{
                    display: block;
                }

                .interval_select{
                    svg{
                        rotate: 90deg;
                    }
                }
            }
        }

        .kick_off_section{
            width: 100%;
            display: flex;
            justify-content: center;
            .kick_off_btn{
                margin-top: 30px;
                font-weight: 700;
                font-size: 16px;
                border: none;
                background: transparent;
                color: #EC7669;
                text-transform: uppercase;
            }
        }
    }
}
