.block_user_modal{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 482px;
    height: 270px;
    z-index: 10;
    position: absolute;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 20px;

    .header_section{
        width: 100%;
        display: flex;
        justify-content: space-between;

        .mofal_name{
            font-weight: 700;
            font-size: 24px;
        }

        .close_popup{
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
    }

    .modal_description{
        font-weight: 700;
        font-size: 16px
    }

    .buttons_section{
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 8px;

        .action_btn{
            font-weight: 700;
            font-size: 16px;
            border: none;
            background: transparent;

            &.block {
                color: #EC7669;
                text-transform: uppercase;
            }
        }
    }
}
