.warning_popup{
    position: absolute;
    top: 0;
    right: 0;
    width: 400px;
    height: 60px;
    background-color: rgb(255, 244, 229);
    border-radius: 4px;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgb(95, 33, 32);
    display: flex;
    align-items: center;
    padding: 0 16px;
    font-size: 0.875rem;
    gap: 8px;
    z-index: 9999;

    .popup_icon {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-size: 15px;
        background-repeat: no-repeat;
        background-position: center;
    }

    &.success {
        background-color: #f6ffed;
        border: 1px solid #b7eb8f;
        .popup_icon{
            background-image: url('../../../assets/icons/warningIcons/success.svg');
            background-color: #52c41a;
        }
    }

    &.warning {
        background-color: #fffbe6;
        border: 1px solid #ffe58f;
        .popup_icon{
            background-image: url('../../../assets/icons/warningIcons/warning.svg');
            background-color: #faad14;
        }
    }

    &.info {
        background-color: #e6f4ff;
        border: 1px solid #91caff;
        .popup_icon{
            background-image: url('../../../assets/icons/warningIcons/info.svg');
            background-color: #1677ff;
        }
    }

    &.error {
        background-color: #fff2f0;
        border: 1px solid #ffccc7;
        .popup_icon{
            background-image: url('../../../assets/icons/warningIcons/error.svg');
            background-color: #ff4d4f;
        }
    }

    .modal_text {
        color: rgba(0, 0, 0, 0.88);
        font-size: 12px;
    }
}