.layer-content {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.mobile{
    .cubeMap {
      justify-content: center;
    }
    .top_container{
      width: 90%;
      top: 3.8rem;

      .left_section{
        margin-left: 0;
      }
    }
    .bottom_container{
      margin-bottom: 100px;
      margin-left: 5%;

      .left_section{
        margin-left: 0;
      }
      .right_section{
        margin-right: 0;
      }
    }

    .space-manager-container{
      width: 100% !important;
      margin-bottom: 120px;

      .carousel{
        width: 90%;
      }
    }

    .unity-board-action-panel{
      right: 3rem;
    }

    .carousel{
      width: 70%;
    }
  }

  &.firefox{
    .bottom_container{
      margin-bottom: 18px;
    }
  }

  &.tablet {
    .space-manager-container{
      margin-bottom: 135px;
    }

    .unity-board-action-panel{
      right: 3rem;
    }

    .bottom_container{
      margin-bottom: 70px;
    }

    .carousel{
      width: 70%;
    }
  }
}

/* Styles for screens in landscape mode */
@media screen and (orientation: landscape) and (max-height: 750px){
  .layer-content {
    justify-content: space-evenly;
  }
}
